import React from 'react';
import NotFound from '../components/NotFound';

export default function Home() {
	// Opinionated: do not record an exception in Sentry for 404
	// return <Error statusCode={404} />;
	return (
		<NotFound />
	);
}
