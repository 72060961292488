import React from 'react';
import NextLink from 'next/link';
import {
	Button,
	Flex, Text,
	Link as ChakraLink,
} from '@chakra-ui/react';
// import Error from 'next/error';
import Page from './Page';

export default function NotFound() {
	return (
		<Page>
			<Flex h="80vh" flexDirection="column" justify="center" alignItems="center">
				<Text as="h2" fontSize="lg">此頁面不存在</Text>
				<ChakraLink as={NextLink} href="/" textDecoration="none">
					<Button
						size="md"
						fontWeight={600}
						px={5}
						py={3}
						mr={1}
						my={2}
						bgColor="whiteAlpha.300"
						color="primary"
					>
						回首頁
					</Button>
				</ChakraLink>
			</Flex>
		</Page>
	);
}
